import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, timer } from 'rxjs';
import { AuthService } from './auth.service';
 //Interceptor..
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { Mensajes } from '../components/shared/mensajes';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private auth: AuthService, 
    private router: Router,
    public msj: Mensajes,
    public sessionService: SessionService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;

    if (this.auth.getToken) {
      request = req.clone({
        setHeaders: {
          Authorization: `${ this.auth.getToken }`
        }
      });
    }
    return next.handle(request).pipe(
      catchError((error) => {
        if (error.status === 401 || error.status === 403) {  // Si el servidor responde con un error 401 (Unauthorized)
          timer(10).subscribe(() => {
            this.msj.mensaje("Su sesión ha finalizado | Verifique sus datos.", 'Cerrar', 'C', 5000);
          });
          this.auth.logout();
          this.sessionService.deleteUser;
        }
        throw error;
      })
    );

    //return next.handle(request);
  }

}
