import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class Mensajes {

  constructor(private _snackBar: MatSnackBar) {}

  mensaje(message: string, action: string, clase: string, milisegundos: number) {
    this._snackBar.dismiss(); // Cierra el mensaje anterior antes de abrir uno nuevo
    this._snackBar.open(message, action, {
      duration: milisegundos,
      verticalPosition: 'top',
      panelClass: [clase],
    });
  }
}
