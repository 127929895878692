import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogsComponent implements OnInit {
  tipoDialogo = '';
  titulo = '';
  msj = '';

  constructor(public dialogRef: MatDialogRef<DialogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.tipoDialogo = this.data.tipo;
    this.msj = this.data.msj;
    this.titulo = this.data.titulo;
  }

  confirmar(valor: boolean) {
    this.dialogRef.close({
      accion: valor
    })
  }

}
