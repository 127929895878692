import { Usuario } from 'src/app/modelo/usuario.model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Routes } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { SessionService } from './session.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginComponent } from '../components/auth/login/login.component';
import { authRestore } from 'src/app/modelo/usuario.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  urlBase: string;
  urlBaseRestore: string;
  token: string;
  usuario: Usuario = {};
  estaLogueado: boolean;
  public usuarioActual$: BehaviorSubject<Usuario>;
  public currentUser: Observable<Usuario>;



  constructor(private router: Router, private _snackBar: MatSnackBar, private http: HttpClient, private sessionService: SessionService) {

    this.urlBase = environment.urlBase + '/auth/login';
    this.urlBaseRestore = environment.urlBase + '/auth/recover';
    this.usuarioActual$ = new BehaviorSubject<Usuario>(this.sessionService.getUser('usuario'));
    this.currentUser = this.usuarioActual$.asObservable();
  }


  public get usuarioActual(): any {
    if (this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined) {
      // this.currentUser = this.sessionService.getUser('usuario');
      // return this.currentUser;

    } else {
      this.logout();
    }

    return this.usuarioActual$.value;

  }

  public get getToken(): string {
    if (this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined) {
      return this.usuarioActual$.value["token"];
    }
  }


  usuarioLogueado() {
    if ((this.sessionService.getUser('usuario') !== null && this.sessionService.getUser('usuario') !== undefined)) {
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  refrescarUsuario(user: Usuario) {
    this.sessionService.setUser('usuario', user);
  }

  public tienePermiso(permiso: string): boolean {
    let poseePermiso: boolean;
    this.usuarioActual.acciones.forEach(x => x.nombre === permiso ? poseePermiso = true : '');
    return poseePermiso;
  }


  getAuth(nombreUsuario: string, claveAcceso: string): Observable<Usuario> {
    return this.http.post<Usuario>(`${this.urlBase}`, { nombreUsuario, claveAcceso })
      .pipe(map(user => {
        if (user.token !== null) {
          this.sessionService.setUser('usuario', user);
          this.usuarioActual$.next(user);
        }
        return user;
      })
      );

  }

  restore(emailParam: string): Observable<string> {

    let authRestore: authRestore = {
      email: emailParam
    }

    return this.http.post<string>(`${this.urlBaseRestore}`, authRestore)
      .pipe(
        take(1)
      );
  }

  canActivate(ruta: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let rutaFraccionada: String [] = [];
    rutaFraccionada = state["url"].split('/', 5);
    const path = rutaFraccionada[rutaFraccionada.length - 1]; // el último tramo
    const confOseguridad = rutaFraccionada[2]; // es configuracion o seguridad

    if (!this.usuarioActual.token !== null) {

        let encontroMenu: boolean;
        this.usuarioActual.menus.find(menu => {

        const menuSinEspacios = menu.nombre.replace(/\s/g, '');
        const sinEspacioNiacentos = menuSinEspacios.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        if (sinEspacioNiacentos.toLowerCase() === path ||
          sinEspacioNiacentos.toLowerCase() === confOseguridad ||
          'perfilusuario' === path) {
            encontroMenu = true;
          }
        });
        if (encontroMenu) {
          return true;
        } else {
        // this.mensajeError('No posee los permisos para acceder a ésta ruta', 'Cerrar', 'C');
          return false;
        }
    }  else {
      return false;
    }
  }

  mensajeError(message: string, action: string, clase: string) {
    this._snackBar.open(message, action, {
      duration: 2200,
      verticalPosition: 'top',
      panelClass: [clase],
    });
  }

  logout() {
     this.router.navigate(['/inicio']);

    this.sessionService.deleteUser('usuario');
    sessionStorage.clear();
    this.estaLogueado = false;

    /*const routes: Routes = [
      { path: '', component: LoginComponent }
    ];*/

}

}
