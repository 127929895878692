<h4> {{titulo}} </h4>

<p> {{msj}} </p>

<div class="center mt-3 ">
  <button mat-raised-button class="btn-aceptar" (click)="confirmar(true)">
    <mat-icon>done</mat-icon>
    SI
  </button>

  <button mat-raised-button class="btn-cancelar"  (click)="confirmar(false)">
    <mat-icon>clear</mat-icon>
    NO
  </button>

</div>
